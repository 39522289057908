// src/utils/tipoLanzamiento.js
const tiposLanzamiento = [
    { value: 1, label: 'Recta' },
    { value: 2, label: 'Cambio' },
    { value: 3, label: 'Curva' },
    { value: 4, label: 'Slider' },
    { value: 5, label: 'Splitter' },
    { value: 6, label: 'Sinker' },
    { value: 7, label: 'Cutter' },
    { value: 7, label: '2F' }
  ];
  
  export default tiposLanzamiento;