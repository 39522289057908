import React, { useState } from 'react';
import { Button, Form, Container, Alert } from 'react-bootstrap';
import axios from 'axios';

const CargarAranceles = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setError("Por favor selecciona un archivo.");
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    const token = localStorage.getItem('jwt');
    if (!token) {
      setError("No se encontró el token de autenticación. Por favor, inicia sesión.");
      return;
    }

    try {
      // Llama al backend para procesar el archivo
      await axios.post(`${process.env.REACT_APP_API_URL}/api/aranceles/importar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
      });
      setMessage("Archivo cargado y procesado correctamente.");
      setError(null);
    } catch (error) {
      console.error('Error al cargar el archivo:', error);
      setError("Error al cargar el archivo.");
      setMessage(null);
    }
  };

  return (
    <Container className="mt-5">
      <h3>Cargar Aranceles Pagados</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formFile">
          <Form.Label>Seleccionar archivo Excel</Form.Label>
          <Form.Control type="file" onChange={handleFileChange} />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3">
          Cargar
        </Button>
      </Form>
      {message && <Alert variant="success" className="mt-3">{message}</Alert>}
      {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
    </Container>
  );
};

export default CargarAranceles;
