import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Extraer el token de la URL si está presente
    const query = new URLSearchParams(window.location.search);
    const token = query.get('token');

    if (token) {
      localStorage.setItem('jwt', token); // Almacenar el token en localStorage
      navigate('/profile'); // Navegar al perfil después de guardar el token
    }
  }, [navigate]);

  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/google`;
  };

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <div className="text-center">
        <h1>¡Bienvenido!</h1>
        <p>Por favor, autentíquese con Google para continuar</p>
        <button className="btn btn-primary" onClick={handleLogin}>
          Iniciar sesión con Google
        </button>
      </div>
    </div>
  );
};

export default Login;
