// src/pages/AdminEntrenamiento.js

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button, Table, Modal } from 'react-bootstrap';
import axios from 'axios';

const AdminEntrenamiento = () => {
  const [templates, setTemplates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEjercicioModal, setShowEjercicioModal] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [ejercicios, setEjercicios] = useState([]);
  const [templateEjercicios, setTemplateEjercicios] = useState([]);
  const [filteredEjercicios, setFilteredEjercicios] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [ejercicioFilter, setEjercicioFilter] = useState({ nombre: '', tipo: '' });
  const [templateFilter, setTemplateFilter] = useState({ nombre: '', tipo: '' });

  const [nuevoEjercicio, setNuevoEjercicio] = useState({
    nombre: '',
    descripcion: '',
    tipo: '',
    link: '',
    variable_sugerida: ''
  });
/*
  const [nuevoTemplate, setNuevoTemplate] = useState({
    nombre: '',
    tipo: ''
  });
*/
  const [nuevoPlan, setNuevoPlan] = useState({
    nombre: '',
    descripcion: '',
    tipo: 'semanal'
  });

  const [planes, setPlanes] = useState([]);

  useEffect(() => {
    fetchEjercicios();
    fetchTemplates();
    fetchPlanes();
  }, []);

  // Funciones para cargar datos
  const fetchEjercicios = async () => {
    try {
      const response = await axios.get('/api/entrenamiento/ejercicios');
      setEjercicios(response.data);
      setFilteredEjercicios(response.data);
    } catch (error) {
      console.error('Error al obtener ejercicios:', error);
    }
  };

  const fetchTemplates = async () => {
    try {
      const response = await axios.get('/api/entrenamiento/templates');
      setTemplates(response.data);
      setFilteredTemplates(response.data);
    } catch (error) {
      console.error('Error al obtener templates:', error);
    }
  };

  const fetchPlanes = async () => {
    try {
      const response = await axios.get('/api/entrenamiento/planes');
      setPlanes(response.data);
    } catch (error) {
      console.error('Error al obtener planes:', error);
    }
  };

  // Manejo de cambios en formularios
  const handleEjercicioChange = (e) => {
    setNuevoEjercicio({ ...nuevoEjercicio, [e.target.name]: e.target.value });
  };
/*
  const handleTemplateChange = (e) => {
    setNuevoTemplate({ ...nuevoTemplate, [e.target.name]: e.target.value });
  };
*/
  const handlePlanChange = (e) => {
    setNuevoPlan({ ...nuevoPlan, [e.target.name]: e.target.value });
  };

  // Funciones para guardar cada tipo de entidad
  const handleSaveEjercicio = async () => {
    try {
      await axios.post('/api/entrenamiento/ejercicios', nuevoEjercicio);
      setNuevoEjercicio({ nombre: '', descripcion: '', tipo: '', link: '', variable_sugerida: '' });
      fetchEjercicios();
      setShowEjercicioModal(false); // Cierra el modal después de guardar
    } catch (error) {
      console.error('Error al guardar el ejercicio:', error);
    }
  };

  /*
  const handleSaveTemplate = async () => {
    try {
      await axios.post('/api/entrenamiento/templates', nuevoTemplate);
      setNuevoTemplate({ nombre: '', tipo: '' });
      fetchTemplates();
    } catch (error) {
      console.error('Error al guardar el template:', error);
    }
  };
*/
  const handleSavePlan = async () => {
    try {
      await axios.post('/api/entrenamiento/planes', nuevoPlan);
      setNuevoPlan({ nombre: '', descripcion: '', tipo: 'semanal' });
      fetchPlanes();
    } catch (error) {
      console.error('Error al guardar el plan:', error);
    }
  };

  // Modal para asociar ejercicios a templates
  const openEjerciciosModal = async (template) => {
    setCurrentTemplate(template);
    try {
      const response = await axios.get(`/api/entrenamiento/templates/${template.ID}/ejercicios`);
      console.log('ejercicios de template',response.data);
      setTemplateEjercicios(response.data.TemplateEjercicioDetalles || []);
      setShowModal(true);
    } catch (error) {
      console.error('Error al obtener ejercicios del template:', error);
    }
  };

  const handleAgregarEjercicio = () => {
    setTemplateEjercicios([
      ...templateEjercicios,
      { EjercicioID: '', series: '', repeticiones: '', peso_sugerido: '' }
    ]);
  };

  const handleChangeEjercicio = (index, field, value) => {
    const updatedEjercicios = [...templateEjercicios];
    updatedEjercicios[index][field] = value;
    setTemplateEjercicios(updatedEjercicios);
  };

  const removeEjercicioFromTemplate = (index) => {
    const updatedEjercicios = templateEjercicios.filter((_, i) => i !== index);
    setTemplateEjercicios(updatedEjercicios);
  };

  const saveEjerciciosToTemplate = async () => {
    try {
      await axios.put(`/api/entrenamiento/templates/${currentTemplate.ID}/ejercicios`, {
        ejercicios: templateEjercicios,
      });
      setShowModal(false);
      fetchTemplates();
    } catch (error) {
      console.error('Error al actualizar los ejercicios del template:', error);
    }
  };

  const deleteEjercicio = async (id) => {

    try {

      await axios.delete(`/api/entrenamiento/ejercicios/${id}`);

      fetchEjercicios();

    } catch (error) {

      console.error('Error al eliminar ejercicio:', error);

    }
  }

  // Filtrar ejercicios y templates
  const handleEjercicioFilterChange = (e) => {
    const { name, value } = e.target;
    setEjercicioFilter({ ...ejercicioFilter, [name]: value });
    setFilteredEjercicios(ejercicios.filter(ej => (
      (ej.nombre.toLowerCase().includes(ejercicioFilter.nombre.toLowerCase())) &&
      (ej.tipo.toLowerCase().includes(ejercicioFilter.tipo.toLowerCase()))
    )));
  };

  const handleTemplateFilterChange = (e) => {
    const { name, value } = e.target;
    setTemplateFilter({ ...templateFilter, [name]: value });
    setFilteredTemplates(templates.filter(t => (
      (t.nombre.toLowerCase().includes(templateFilter.nombre.toLowerCase())) &&
      (t.tipo.toLowerCase().includes(templateFilter.tipo.toLowerCase()))
    )));
  };

  return (
    <Container>
      <h1>Administración de Entrenamiento</h1>

      {/* Sección Ejercicios */}
      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Header>
              Ejercicios
              <Button variant="primary" className="float-end" onClick={() => setShowEjercicioModal(true)}>Agregar Ejercicio</Button>
            </Card.Header>
            <Card.Body>
              <Form className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Buscar por nombre"
                  name="nombre"
                  value={ejercicioFilter.nombre}
                  onChange={(e) => handleEjercicioFilterChange(e)}
                />
                <Form.Control
                  type="text"
                  placeholder="Buscar por tipo"
                  name="tipo"
                  value={ejercicioFilter.tipo}
                  onChange={(e) => handleEjercicioFilterChange(e)}
                  className="mt-2"
                />
              </Form>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Descripción</th>
                    <th>Tipo</th>
                    <th>Link</th>
                    <th>Variable Sugerida</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(filteredEjercicios) && filteredEjercicios.map(ej => (
                    <tr key={ej.ID}>
                      <td>{ej.nombre}</td>
                      <td>{ej.descripcion}</td>
                      <td>{ej.tipo}</td>
                      <td><a href={ej.link} target="_blank" rel="noopener noreferrer">{ej.link ? 'Ver' : ''}</a></td>
                      <td>{ej.variable_sugerida}</td>
                      <td>
                        <Button variant="danger" size="sm" onClick={() => deleteEjercicio(ej.ID)}>Eliminar</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal para agregar nuevo ejercicio */}
      <Modal show={showEjercicioModal} onHide={() => setShowEjercicioModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Nuevo Ejercicio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEjercicioNombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control type="text" name="nombre" value={nuevoEjercicio.nombre} onChange={handleEjercicioChange} />
            </Form.Group>
            <Form.Group controlId="formEjercicioDescripcion">
              <Form.Label>Descripción</Form.Label>
              <Form.Control type="text" name="descripcion" value={nuevoEjercicio.descripcion} onChange={handleEjercicioChange} />
            </Form.Group>
            <Form.Group controlId="formEjercicioTipo">
              <Form.Label>Tipo</Form.Label>
              <Form.Control type="text" name="tipo" value={nuevoEjercicio.tipo} onChange={handleEjercicioChange} />
            </Form.Group>
            <Form.Group controlId="formEjercicioLink">
              <Form.Label>Link</Form.Label>
              <Form.Control type="text" name="link" value={nuevoEjercicio.link} onChange={handleEjercicioChange} />
            </Form.Group>
            <Form.Group controlId="formEjercicioVariableSugerida">
              <Form.Label>Variable Sugerida</Form.Label>
              <Form.Control type="text" name="variable_sugerida" value={nuevoEjercicio.variable_sugerida} onChange={handleEjercicioChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEjercicioModal(false)}>Cerrar</Button>
          <Button variant="primary" onClick={handleSaveEjercicio}>Guardar</Button>
        </Modal.Footer>
      </Modal>

      {/* Sección Templates */}
      {/* Sección Templates */}
      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Header>Templates de Ejercicio</Card.Header>
            <Card.Body>
              <Form className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Buscar por nombre"
                  name="nombre"
                  value={templateFilter.nombre}
                  onChange={handleTemplateFilterChange}
                />
                <Form.Control
                  type="text"
                  placeholder="Buscar por tipo"
                  name="tipo"
                  value={templateFilter.tipo}
                  onChange={handleTemplateFilterChange}
                  className="mt-2"
                />
              </Form>
              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Tipo</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTemplates.map(t => (
                    <tr key={t.ID}>
                      <td>{t.nombre}</td>
                      <td>{t.tipo}</td>
                      <td>
                        <Button variant="secondary" onClick={() => openEjerciciosModal(t)}>
                          Ver y Agregar Ejercicios
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal para administrar ejercicios de template */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Ejercicios en {currentTemplate?.nombre}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Ejercicio</th>
                <th>Series</th>
                <th>Repeticiones</th>
                <th>Peso Sugerido</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {templateEjercicios.map((ej, index) => (
                <tr key={index}>
                  <td>
                    <Form.Control
                      as="select"
                      value={ej.EjercicioID}
                      onChange={(e) => handleChangeEjercicio(index, 'EjercicioID', e.target.value)}
                    >
                      <option value="">Seleccionar Ejercicio</option>
                      {ejercicios.map((ejercicio) => (
                        <option key={ejercicio.ID} value={ejercicio.ID}>
                          {ejercicio.nombre}
                        </option>
                      ))}
                    </Form.Control>
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      value={ej.series}
                      onChange={(e) => handleChangeEjercicio(index, 'series', e.target.value)}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      value={ej.repeticiones}
                      onChange={(e) => handleChangeEjercicio(index, 'repeticiones', e.target.value)}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={ej.peso_sugerido}
                      onChange={(e) => handleChangeEjercicio(index, 'peso_sugerido', e.target.value)}
                    />
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => removeEjercicioFromTemplate(index)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button variant="secondary" onClick={handleAgregarEjercicio} className="mt-3">
            Agregar Ejercicio
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={saveEjerciciosToTemplate}>
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Sección Planes */}
      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Header>Planes de Entrenamiento</Card.Header>
            <Card.Body>
              <Form>
                <Form.Group controlId="formPlanNombre">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control type="text" name="nombre" value={nuevoPlan.nombre} onChange={handlePlanChange} />
                </Form.Group>
                <Form.Group controlId="formPlanDescripcion">
                  <Form.Label>Descripción</Form.Label>
                  <Form.Control type="text" name="descripcion" value={nuevoPlan.descripcion} onChange={handlePlanChange} />
                </Form.Group>
                <Form.Group controlId="formPlanTipo">
                  <Form.Label>Tipo</Form.Label>
                  <Form.Control as="select" name="tipo" value={nuevoPlan.tipo} onChange={handlePlanChange}>
                    <option value="semanal">Semanal</option>
                    <option value="mensual">Mensual</option>
                  </Form.Control>
                </Form.Group>
                <Button variant="primary" onClick={handleSavePlan} className="mt-3">Guardar Plan</Button>
              </Form>
              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Descripción</th>
                    <th>Tipo</th>
                  </tr>
                </thead>
                <tbody>
                  {planes.map(p => (
                    <tr key={p.ID}>
                      <td>{p.nombre}</td>
                      <td>{p.descripcion}</td>
                      <td>{p.tipo}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminEntrenamiento;
