import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Table, Form } from 'react-bootstrap';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

const ControlPresentismo = () => {
  const [promedioAsistencia, setPromedioAsistencia] = useState([]);
  const [reporteAsistencia, setReporteAsistencia] = useState([]);
  const [mesSeleccionado, setMesSeleccionado] = useState(moment().month() + 1);
  const [ultimoPagoData, setUltimoPagoData] = useState([]);

  useEffect(() => {
    fetchPromedioAsistencia();
    fetchReporteAsistencia(mesSeleccionado);
    fetchUltimoPagoData();
  }, [mesSeleccionado]);

  const fetchPromedioAsistencia = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/presentismo/promedio-dia`);
      setPromedioAsistencia(response.data);
    } catch (error) {
      console.error('Error al obtener promedio de asistencia:', error);
    }
  };

  const fetchReporteAsistencia = async (mes) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/presentismo/reporte/${mes}`);
      setReporteAsistencia(response.data);
    } catch (error) {
      console.error('Error al obtener el reporte de asistencia:', error);
    }
  };

  const fetchUltimoPagoData = async () => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('Token de autenticación no encontrado');
      return;
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/aranceles/ultimo-pago`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setUltimoPagoData(response.data);
    } catch (error) {
      console.error('Error al obtener datos del último pago:', error);
    }
  };

  const obtenerUltimoArancel = (jugadorId) => {
    const pago = ultimoPagoData.find((p) => p.JugadorID === jugadorId);
    return pago ? moment(pago.UltimoMesPago).format('MM/YYYY') : 'N/A';
  };

  const chartData = {
    labels: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    datasets: [
      {
        label: 'Promedio de Asistencia',
        data: promedioAsistencia.map((dia) => dia.promedio_asistencia),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  const determinarColor = (asistencias) => {
    if (asistencias === 0) return 'table-danger';
    if (asistencias < 5) return 'table-warning';
    return 'table-success';
  };

  return (
    <Container fluid className="mt-4">
      <Row className="mb-4">
        <Col md={12}>
          <Card>
            <Card.Header>Promedio de Asistencia por Día de la Semana</Card.Header>
            <Card.Body>
              <Bar data={chartData} />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={12}>
          <Card>
            <Card.Header>Reporte General de Asistencia</Card.Header>
            <Card.Body>
              <Form.Select
                value={mesSeleccionado}
                onChange={(e) => setMesSeleccionado(e.target.value)}
                className="mb-3"
              >
                {Array.from({ length: 12 }).map((_, index) => (
                  <option key={index + 1} value={index + 1}>
                    {moment().month(index).format('MMMM')}
                  </option>
                ))}
              </Form.Select>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Apellido</th>
                    <th>Total Asistencias</th>
                    <th>Último Arancel Pago</th>
                  </tr>
                </thead>
                <tbody>
                  {reporteAsistencia.map((jugador) => (
                    <tr key={jugador.jugador_id} className={determinarColor(jugador.total_asistencias)}>
                      <td>{jugador.nombre}</td>
                      <td>{jugador.apellido}</td>
                      <td>{jugador.total_asistencias}</td>
                      <td>{obtenerUltimoArancel(jugador.jugador_id)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ControlPresentismo;
