import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const EstadisticasJugador = () => {
  const { jugadorID } = useParams();
  const [jugador, setJugador] = useState({});
  const [estadisticasActuales, setEstadisticasActuales] = useState([]);
  const [estadisticasHistoricas, setEstadisticasHistoricas] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    const authHeader = {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    // Obtener información del jugador
    const fetchJugador = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jugadores/${jugadorID}`, authHeader);
        setJugador(response.data);
      } catch (error) {
        console.error('Error al obtener el jugador:', error);
      }
    };

    // Obtener estadísticas actuales
    const fetchEstadisticasActuales = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/estadisticas/bateo/actuales/${jugadorID}`, authHeader);
        setEstadisticasActuales(response.data);
      } catch (error) {
        console.error('Error al obtener estadísticas actuales:', error);
      }
    };

    // Obtener estadísticas históricas
    const fetchEstadisticasHistoricas = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/estadisticas/bateo/historico/${jugadorID}`, authHeader);
        setEstadisticasHistoricas(response.data);
      } catch (error) {
        console.error('Error al obtener estadísticas históricas:', error);
      }
    };

    fetchJugador();
    fetchEstadisticasActuales();
    fetchEstadisticasHistoricas();
  }, [jugadorID]);

  return (
    <div>
      <h1>Estadísticas de {jugador.nombre} {jugador.apellido}</h1>

      {/* Estadísticas de Bateo */}
      <Card className="my-3">
        <Card.Header>Estadísticas de Bateo</Card.Header>
        <Card.Body>
          <h2>Torneos Actuales</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Categoría</th>
                <th>Equipo Rival</th>
                <th>G</th>
                <th>AB</th>
                <th>R</th>
                <th>H</th>
                <th>1B</th>
                <th>2B</th>
                <th>3B</th>
                <th>HR</th>
                <th>RBI</th>
                <th>BB</th>
                <th>SO</th>
                <th>AVG</th>
                <th>OBP</th>
                <th>SLG</th>
                <th>OPS</th>
              </tr>
            </thead>
            <tbody>
              {estadisticasActuales.map((estadistica) => (
                <tr key={estadistica.PartidoID}>
                  <td>{estadistica.Fecha}</td>
                  <td>{estadistica.Categoria}</td>
                  <td>{estadistica.EquipoRival}</td>
                  <td>{estadistica.G}</td>
                  <td>{estadistica.AB}</td>
                  <td>{estadistica.R}</td>
                  <td>{estadistica.H}</td>
                  <td>{estadistica.singles}</td>
                  <td>{estadistica.dobles}</td>
                  <td>{estadistica.triples}</td>
                  <td>{estadistica.HR}</td>
                  <td>{estadistica.RBI}</td>
                  <td>{estadistica.BB}</td>
                  <td>{estadistica.SO}</td>
                  <td>{estadistica.AVG}</td>
                  <td>{estadistica.OBP}</td>
                  <td>{estadistica.SLG}</td>
                  <td>{estadistica.OPS}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <h2>Histórico</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Torneo</th>
                <th>G</th>
                <th>AB</th>
                <th>R</th>
                <th>H</th>
                <th>1B</th>
                <th>2B</th>
                <th>3B</th>
                <th>HR</th>
                <th>RBI</th>
                <th>BB</th>
                <th>SO</th>
                <th>AVG</th>
                <th>OBP</th>
                <th>SLG</th>
                <th>OPS</th>
              </tr>
            </thead>
            <tbody>
              {estadisticasHistoricas.map((estadistica) => (
                <tr key={estadistica.TorneoID}>
                  <td>{estadistica.Nombre}</td>
                  <td>{estadistica.G}</td>
                  <td>{estadistica.AB}</td>
                  <td>{estadistica.R}</td>
                  <td>{estadistica.H}</td>
                  <td>{estadistica.singles}</td>
                  <td>{estadistica.dobles}</td>
                  <td>{estadistica.triples}</td>
                  <td>{estadistica.HR}</td>
                  <td>{estadistica.RBI}</td>
                  <td>{estadistica.BB}</td>
                  <td>{estadistica.SO}</td>
                  <td>{estadistica.AVG}</td>
                  <td>{estadistica.OBP}</td>
                  <td>{estadistica.SLG}</td>
                  <td>{estadistica.OPS}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {/* Sección para estadísticas de pitcheo se agregará en el futuro */}
    </div>
  );
};

export default EstadisticasJugador;
