import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Button, Form, Row, Col, Modal, Table } from 'react-bootstrap';
import moment from 'moment';
import equipos from '../utils/equipos'; // Archivo de equipos
import campos from '../utils/campos'; // Archivo de campos
import categorias from '../utils/categorias'; // Archivo de categorías

const Partidos = () => {
  const [partidos, setPartidos] = useState([]);
  const [torneos, setTorneos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showFileUpload, setShowFileUpload] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filters, setFilters] = useState({
    Categoria: '',
    Rival: '',
    Fecha: '',
    TorneoID: ''
  });
  const [nuevoPartido, setNuevoPartido] = useState({
    TorneoID: '',
    Fecha: '',
    Rival: '',
    Campo: '',
    Categoria: '',
    LocalVisita: 'Local',
    CarrerasAFavor: '',
    CarrerasEnContra: '',
    Arbitro: '',
  });

  useEffect(() => {
    fetchPartidos();
    fetchTorneos();
  }, []);

  const fetchPartidos = async () => {
    const token = localStorage.getItem('jwt');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/partidos`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const sortedPartidos = response.data.sort(
        (a, b) => new Date(b.Fecha) - new Date(a.Fecha)
      );
      setPartidos(sortedPartidos);
    } catch (error) {
      console.error('Error fetching partidos:', error);
    }
  };

  const fetchTorneos = async () => {
    const token = localStorage.getItem('jwt');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/torneos`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setTorneos(response.data);
    } catch (error) {
      console.error('Error fetching torneos:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNuevoPartido((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSavePartido = async () => {
    const token = localStorage.getItem('jwt');
    try {
      if (nuevoPartido.id) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/partidos/${nuevoPartido.id}`, nuevoPartido, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/partidos`, nuevoPartido, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      }
      fetchPartidos();
      handleCloseModal();
    } catch (error) {
      console.error('Error saving partido:', error);
    }
  };

  const handleDeletePartido = async (partidoId) => {
    const token = localStorage.getItem('jwt');
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/partidos/${partidoId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      fetchPartidos();
    } catch (error) {
      console.error('Error deleting partido:', error);
    }
  };

  const handleEditPartido = (partido) => {
    setNuevoPartido(partido);
    setShowModal(true);
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setNuevoPartido({
      TorneoID: '',
      Fecha: '',
      Rival: '',
      Campo: '',
      Categoria: '',
      LocalVisita: 'Local',
      CarrerasAFavor: '',
      CarrerasEnContra: '',
      Arbitro: '',
    });
    setShowModal(false);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const filteredPartidos = partidos.filter((partido) => {
    return (
      (filters.Categoria ? partido.Categoria === filters.Categoria : true) &&
      (filters.Rival ? partido.Rival === filters.Rival : true) &&
      (filters.Fecha ? moment(partido.Fecha).isSame(filters.Fecha, 'day') : true) &&
      (filters.TorneoID ? partido.TorneoID === parseInt(filters.TorneoID) : true)
    );
  });

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUploadFile = async (torneoID, partidoID) => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('file', selectedFile);
    const token = localStorage.getItem('jwt');

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/estadisticasBateo/upload?torneoID=${torneoID}&partidoID=${partidoID}`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('Archivo cargado correctamente');
      window.location.href = `/match-jugadores/${torneoID}/${partidoID}`;
    } catch (error) {
      console.error('Error al cargar el archivo:', error);
    }
  };

  return (
    <div>
      <h1>Administrar Partidos</h1>
      <Button variant="primary" onClick={handleShowModal}>
        Agregar Nuevo Partido
      </Button>

      {/* Filtros */}
      <Form className="my-4">
        <Row>
          <Col>
            <Form.Group controlId="filterCategoria">
              <Form.Label>Categoría</Form.Label>
              <Form.Control as="select" name="Categoria" value={filters.Categoria} onChange={handleFilterChange}>
                <option value="">Todas las categorías</option>
                {Object.entries(categorias).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="filterRival">
              <Form.Label>Rival</Form.Label>
              <Form.Control as="select" name="Rival" value={filters.Rival} onChange={handleFilterChange}>
                <option value="">Todos los rivales</option>
                {Object.entries(equipos).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="filterFecha">
              <Form.Label>Fecha</Form.Label>
              <Form.Control type="date" name="Fecha" value={filters.Fecha} onChange={handleFilterChange} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="filterTorneo">
              <Form.Label>Torneo</Form.Label>
              <Form.Control as="select" name="TorneoID" value={filters.TorneoID} onChange={handleFilterChange}>
                <option value="">Todos los torneos</option>
                {torneos.map((torneo) => (
                  <option key={torneo.id} value={torneo.id}>
                    {torneo.Nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </Form>

      {/* Modal para agregar o editar un partido */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{nuevoPartido.id ? 'Editar Partido' : 'Agregar Nuevo Partido'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTorneoID">
              <Form.Label>Torneo</Form.Label>
              <Form.Control
                as="select"
                name="TorneoID"
                value={nuevoPartido.TorneoID}
                onChange={handleInputChange}
              >
                <option value="">Selecciona un torneo (opcional)</option>
                {torneos.map((torneo) => (
                  <option key={torneo.id} value={torneo.id}>
                    {torneo.Nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formFecha">
              <Form.Label>Fecha</Form.Label>
              <Form.Control
                type="date"
                name="Fecha"
                value={nuevoPartido.Fecha}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formRival">
              <Form.Label>Rival</Form.Label>
              <Form.Control
                as="select"
                name="Rival"
                value={nuevoPartido.Rival}
                onChange={handleInputChange}
              >
                <option value="">Selecciona un rival</option>
                {Object.entries(equipos).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formCampo">
              <Form.Label>Campo</Form.Label>
              <Form.Control
                as="select"
                name="Campo"
                value={nuevoPartido.Campo}
                onChange={handleInputChange}
              >
                <option value="">Selecciona un campo</option>
                {Object.entries(campos).map(([key, campo]) => (
                  <option key={key} value={key}>
                    {campo.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formCategoria">
              <Form.Label>Categoría</Form.Label>
              <Form.Control
                as="select"
                name="Categoria"
                value={nuevoPartido.Categoria}
                onChange={handleInputChange}
              >
                <option value="">Selecciona una categoría</option>
                {Object.entries(categorias).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formLocalVisita">
              <Form.Label>Local/Visita</Form.Label>
              <Form.Control
                as="select"
                name="LocalVisita"
                value={nuevoPartido.LocalVisita}
                onChange={handleInputChange}
              >
                <option value="Local">Local</option>
                <option value="Visita">Visita</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formCarrerasAFavor">
              <Form.Label>Carreras a Favor</Form.Label>
              <Form.Control
                type="number"
                name="CarrerasAFavor"
                value={nuevoPartido.CarrerasAFavor}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formCarrerasEnContra">
              <Form.Label>Carreras en Contra</Form.Label>
              <Form.Control
                type="number"
                name="CarrerasEnContra"
                value={nuevoPartido.CarrerasEnContra}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formArbitro">
              <Form.Label>Árbitro</Form.Label>
              <Form.Control
                type="text"
                name="Arbitro"
                value={nuevoPartido.Arbitro}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSavePartido}>
            {nuevoPartido.id ? 'Actualizar Partido' : 'Guardar Partido'}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Listado de Partidos */}
      <Card>
        <Card.Header>Partidos</Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Categoría</th>
                <th>VS</th>
                <th>Fecha</th>
                <th>Resultado</th>
                <th>Torneo</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {filteredPartidos.map((partido) => (
                <tr key={partido.id}>
                  <td>{categorias[partido.Categoria]}</td>
                  <td>{equipos[partido.Rival]}</td>
                  <td>{moment(partido.Fecha).format('DD/MM/YYYY')}</td>
                  <td>{partido.CarrerasAFavor} - {partido.CarrerasEnContra}</td>
                  <td>{torneos.find((t) => t.id === partido.TorneoID)?.Nombre || 'N/A'}</td>
                  <td>
                    <Button variant="warning" onClick={() => handleEditPartido(partido)} className="me-2">
                      Editar
                    </Button>
                    <Button variant="danger" onClick={() => handleDeletePartido(partido.id)} className="me-2">
                      Eliminar
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => setShowFileUpload(partido.id)}
                    >
                      Cargar Stats
                    </Button>
                    {showFileUpload === partido.id && (
                      <>
                        <Form.Group controlId={`upload-file-${partido.id}`} className="mt-2">
                          <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                        <Button
                          className="mt-2"
                          variant="primary"
                          onClick={() => handleUploadFile(partido.TorneoID, partido.id)}
                        >
                          Cargar Stats Bateo
                        </Button>
                        <Button
                          className="mt-2"
                          variant="secondary"
                          onClick={() => alert('Funcionalidad de Cargar Stats Pitcheo aún no implementada')}
                        >
                          Cargar Stats Pitcheo
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Partidos;
