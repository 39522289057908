// src/utils/banderas.js
const banderas = {
  1: { nombre: 'Argentina', url: 'https://flagcdn.com/w320/ar.png' },
  2: { nombre: 'Venezuela', url: 'https://flagcdn.com/w320/ve.png' },
  3: { nombre: 'Bolivia', url: 'https://flagcdn.com/w320/bo.png' },
  4: { nombre: 'Taiwan', url: 'https://flagcdn.com/w320/tw.png' },
  5: { nombre: 'Cuba', url: 'https://flagcdn.com/w320/cu.png' },
  6: { nombre: 'Colombia', url: 'https://flagcdn.com/w320/co.png' },
  7: { nombre: 'Italia', url: 'https://flagcdn.com/w320/it.png' },
  8: { nombre: 'USA', url: 'https://flagcdn.com/w320/us.png' },
  9: { nombre: 'República Dominicana', url: 'https://flagcdn.com/w320/do.png' },
  10: { nombre: 'Canadá', url: 'https://flagcdn.com/w320/ca.png' },
  11: { nombre: 'Rusia', url: 'https://flagcdn.com/w320/ru.png' },
  12: { nombre: 'Ecuador', url: 'https://flagcdn.com/w320/ec.png' },
  13: { nombre: 'Japón', url: 'https://flagcdn.com/w320/jp.png' },
  14: { nombre: 'Corea del Sur', url: 'https://flagcdn.com/w320/kr.png' },
};

export default banderas;

  