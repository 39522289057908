// src/pages/Dashboard.js
import React from 'react';
import { Container } from 'react-bootstrap';

const Dashboard = () => {
  return (
    <Container>
      <h1>Dashboard</h1>
      <p>Esta es la página del Dashboard. Aquí podrás ver información general una vez que esté construida.</p>
    </Container>
  );
};

export default Dashboard;
