import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Table } from 'react-bootstrap';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Dependencias para gráficos de barras
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ControlAranceles = () => {
  const [arancelesData, setArancelesData] = useState([]);
  const [sinPagoData, setSinPagoData] = useState([]);
  const [ultimoPagoData, setUltimoPagoData] = useState([]);
  const [ultimaActualizacion, setUltimaActualizacion] = useState(null);

  useEffect(() => {
    // Cargar métricas de aranceles
    const fetchArancelesData = async () => {
      const token = localStorage.getItem('jwt');
      if (!token) {
        console.error('Token de autenticación no encontrado');
        return;
      }
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/aranceles/graficos`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setArancelesData(response.data);
      } catch (error) {
        console.error('Error al obtener los aranceles:', error);
      }
    };

    // Cargar datos de jugadores sin pago
    const fetchSinPagoData = async () => {
      const token = localStorage.getItem('jwt');
      if (!token) {
        console.error('Token de autenticación no encontrado');
        return;
      }
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/aranceles/sin-pago`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setSinPagoData(response.data);
      } catch (error) {
        console.error('Error al obtener jugadores sin pago:', error);
      }
    };

    // Cargar datos del último pago
    const fetchUltimoPagoData = async () => {
      const token = localStorage.getItem('jwt');
      if (!token) {
        console.error('Token de autenticación no encontrado');
        return;
      }
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/aranceles/ultimo-pago`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setUltimoPagoData(response.data);
      } catch (error) {
        console.error('Error al obtener datos del último pago:', error);
      }
    };

    // Cargar fecha de última actualización
    const fetchUltimaActualizacion = async () => {
      const token = localStorage.getItem('jwt');
      if (!token) {
        console.error('Token de autenticación no encontrado');
        return;
      }
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/aranceles/ultima-actualizacion`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.data) {
          setUltimaActualizacion(moment(response.data).isValid() ? response.data : null); // Verificar si es una fecha válida
        } else {
          setUltimaActualizacion(null); // Si no hay fecha, poner null
        }
      } catch (error) {
        console.error('Error al obtener la fecha de última actualización:', error);
      }
    };

    fetchArancelesData();
    fetchSinPagoData();
    fetchUltimoPagoData();
    fetchUltimaActualizacion();
  }, []);

  // Datos del gráfico de barras apilado
  const chartData = {
    labels: arancelesData.map((entry) => entry.mes),
    datasets: [
      {
        label: 'Federados',
        data: arancelesData.map((entry) => entry.federados),
        backgroundColor: '#42A5F5',
      },
      {
        label: 'Escuela',
        data: arancelesData.map((entry) => entry.escuela),
        backgroundColor: '#66BB6A',
      },
      // Agregar más tipos de ser necesario
    ],
  };

  const chartOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Cantidad de Aranceles por Mes (últimos 12 meses)',
      },
      legend: {
        position: 'top',
      },
    },
    responsive: true,
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
  };

  // Función para determinar el color de la celda
  const determinarColor = (ultimoMesPago) => {
    if (!ultimaActualizacion || !ultimoMesPago) return '';

    const diffMeses = moment(ultimaActualizacion).diff(moment(ultimoMesPago), 'months');

    if (diffMeses <= 1) {
      return 'table-success'; // verde
    } else if (diffMeses === 2) {
      return 'table-warning'; // amarillo
    } else if (diffMeses > 2) {
      return 'table-danger'; // rojo
    }
    return '';
  };

  return (
    <Container fluid>
      {ultimaActualizacion && (
        <p>Última actualización de aranceles: {moment(ultimaActualizacion).format('DD/MM/YYYY')}</p>
      )}
      <Row className="mb-4">
        <Col md={12}>
          <Card>
            <Card.Header>Control de Aranceles</Card.Header>
            <Card.Body>
              <Bar data={chartData} options={chartOptions} />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Panel de jugadores sin pago */}
      <Row className="mb-4">
        <Col md={12}>
          <Card className="border-danger">
            <Card.Header className="bg-danger text-white">Jugadores Sin Arancel Pago</Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Fecha de Nacimiento</th>
                    <th>Categoría</th>
                    <th>Teléfono</th>
                  </tr>
                </thead>
                <tbody>
                  {sinPagoData.map((jugador) => (
                    <tr key={jugador.JugadorID}>
                      <td>
                        <Link to={`/jugador/${jugador.JugadorID}`}>
                          {jugador.Nombre}
                        </Link>
                      </td>
                      <td>{jugador.FechaNacimiento}</td>
                      <td>{jugador.Categoria}</td>
                      <td>
                        <a href={`https://wa.me/549${jugador.Telefono}`}>
                          {jugador.Telefono}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Panel de último arancel pago */}
      <Row className="mb-4">
        <Col md={12}>
          <Card>
            <Card.Header>Último Arancel Pago</Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Fecha de Nacimiento</th>
                    <th>Categoría</th>
                    <th>Teléfono</th>
                    <th>Último Mes de Pago</th>
                  </tr>
                </thead>
                <tbody>
                  {ultimoPagoData.map((jugador) => (
                    <tr key={jugador.JugadorID}>
                      <td>
                        <Link to={`/jugador/${jugador.JugadorID}`}>
                          {jugador.Nombre}
                        </Link>
                      </td>
                      <td>{jugador.FechaNacimiento}</td>
                      <td>{jugador.Categoria}</td>
                      <td>
                        <a href={`https://wa.me/549${jugador.Telefono}`}>
                          {jugador.Telefono}
                        </a>
                      </td>
                      <td className={determinarColor(jugador.UltimoMesPago)}>{jugador.UltimoMesPago}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ControlAranceles;
