import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Table } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import categorias from '../utils/categorias';

const Torneos = () => {
  const [torneos, setTorneos] = useState([]);
  const [nuevoTorneo, setNuevoTorneo] = useState({
    Nombre: '',
    FechaInicio: '',
    FechaFin: '',
    Tipo: 'Local',
    Categoria: ''
  });
  const navigate = useNavigate();
  const [editando, setEditando] = useState(null); // Para editar un torneo
  const [selectedFile, setSelectedFile] = useState(null); // Estado para el archivo Excel
  const [showFileUpload, setShowFileUpload] = useState(null); // Controlar la visibilidad del input

  // Función memorizada para obtener torneos
  const fetchTorneos = useCallback(async () => {
    const token = localStorage.getItem('jwt'); // Obtener el token de localStorage
    if (!token) {
      console.error('Token de autenticación no encontrado');
      return;
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/torneos`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setTorneos(response.data);
    } catch (error) {
      console.error('Error al obtener los torneos:', error);
    }
  }, []);

  useEffect(() => {
    fetchTorneos();
  }, [fetchTorneos]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNuevoTorneo({
      ...nuevoTorneo,
      [name]: value,
    });
  };

  const handleCrearTorneo = async () => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('Token de autenticación no encontrado');
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/torneos/crear`, nuevoTorneo, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      fetchTorneos();
      setNuevoTorneo({
        Nombre: '',
        FechaInicio: '',
        FechaFin: '',
        Tipo: 'Local',
        Categoria: ''
      });
    } catch (error) {
      console.error('Error al crear el torneo:', error);
    }
  };

  const handleEditarTorneo = async (id) => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('Token de autenticación no encontrado');
      return;
    }
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/torneos/actualizar/${id}`, nuevoTorneo, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      fetchTorneos();
      setEditando(null);
      setNuevoTorneo({
        Nombre: '',
        FechaInicio: '',
        FechaFin: '',
        Tipo: 'Local',
        Categoria: ''
      });
    } catch (error) {
      console.error('Error al actualizar el torneo:', error);
    }
  };

  const iniciarEdicion = (torneo) => {
    setNuevoTorneo(torneo);
    setEditando(torneo.id);
  };

  // Manejar la selección del archivo
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  // Enviar el archivo Excel al backend
  const handleUploadFile = async (torneoID) => {
    const token = localStorage.getItem('jwt');
    if (!selectedFile || !token) {
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/estadisticasBateo/upload?torneoID=${torneoID}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      alert('Archivo cargado correctamente');
      navigate(`/match-jugadores/${torneoID}`);
    } catch (error) {
      console.error('Error al cargar el archivo:', error);
    }
  };

  return (
    <div className="container mt-4">
      <h1>Administración de Torneos</h1>

      {/* Formulario de creación/edición */}
      <Card className="mb-4">
        <Card.Header>{editando ? 'Editar Torneo' : 'Crear Nuevo Torneo'}</Card.Header>
        <Card.Body>
          <Form>
            {/* Los campos para crear y editar torneos */}
            <Row>
              <Col md={4}>
                <Form.Group controlId="formNombre">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type="text"
                    name="Nombre"
                    value={nuevoTorneo.Nombre}
                    onChange={handleInputChange}
                    placeholder="Nombre del torneo"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formFechaInicio">
                  <Form.Label>Fecha Inicio</Form.Label>
                  <Form.Control
                    type="date"
                    name="FechaInicio"
                    value={nuevoTorneo.FechaInicio}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formFechaFin">
                  <Form.Label>Fecha Fin</Form.Label>
                  <Form.Control
                    type="date"
                    name="FechaFin"
                    value={nuevoTorneo.FechaFin}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={6}>
                <Form.Group controlId="formTipo">
                  <Form.Label>Tipo</Form.Label>
                  <Form.Control
                    as="select"
                    name="Tipo"
                    value={nuevoTorneo.Tipo}
                    onChange={handleInputChange}
                  >
                    <option value="Local">Local</option>
                    <option value="Nacional por equipos">Nacional por equipos</option>
                    <option value="Nacional Selecciones">Nacional Selecciones</option>
                    <option value="Internacional Equipos">Internacional Equipos</option>
                    <option value="Internacional Selecciones">Internacional Selecciones</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formCategoria">
                  <Form.Label>Categoría</Form.Label>
                  <Form.Control
                    as="select"
                    name="Categoria"
                    value={nuevoTorneo.Categoria}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecciona una categoría</option>
                    {Object.entries(categorias).map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Button
              className="mt-3"
              variant={editando ? 'warning' : 'primary'}
              onClick={editando ? () => handleEditarTorneo(editando) : handleCrearTorneo}
            >
              {editando ? 'Guardar Cambios' : 'Crear Torneo'}
            </Button>
          </Form>
        </Card.Body>
      </Card>

      {/* Listado de torneos */}
      <Card>
        <Card.Header>Lista de Torneos</Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Fecha Inicio</th>
                <th>Fecha Fin</th>
                <th>Tipo</th>
                <th>Categoría</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {torneos.map((torneo) => (
                <tr key={torneo.id}>
                  <td>{torneo.Nombre}</td>
                  <td>{moment(torneo.FechaInicio).format('DD/MM/YYYY')}</td>
                  <td>{moment(torneo.FechaFin).format('DD/MM/YYYY')}</td>
                  <td>{torneo.Tipo}</td>
                  <td>{categorias[torneo.Categoria]}</td>
                  <td>
                    <Button variant="warning" onClick={() => iniciarEdicion(torneo)}>
                      Editar
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => setShowFileUpload(torneo.id)}
                    >
                      Cargar Stats
                    </Button>

                    {/* Mostrar el input y los botones solo si se ha clicado en "Cargar Stats" */}
                    {showFileUpload === torneo.id && (
                      <>
                        <Form.Group controlId={`upload-file-${torneo.id}`} className="mt-2">
                          <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                        <Button
                          className="mt-2"
                          variant="primary"
                          onClick={() => handleUploadFile(torneo.id)}
                        >
                          Cargar Stats Bateo
                        </Button>
                        <Button
                          className="mt-2"
                          variant="secondary"
                          onClick={() => alert('Funcionalidad de Cargar Stats Pitcheo aún no implementada')}
                        >
                          Cargar Stats Pitcheo
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Torneos;
