// Un ejemplo en un componente React (puedes ponerlo en App.js o en cualquier otro componente)
import React, { useEffect } from 'react';

const TestAuth = () => {
  const testAuth = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/test-auth`, {
        credentials: 'include', // Asegúrate de incluir las credenciales
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Respuesta del servidor:', data); // Debería mostrar el mensaje de éxito
      } else {
        console.error('Error de autenticación:', response.statusText);
      }
    } catch (error) {
      console.error('Error al probar autenticación:', error);
    }
  };

  useEffect(() => {
    // Llama a la función de prueba de autenticación
    testAuth();
  }, []);

  return (
    <div>
      <h1>Prueba de Autenticación</h1>
      <p>Revisa la consola para ver los resultados de la prueba.</p>
    </div>
  );
};

export default TestAuth;
