import React, { useState } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';

const CargaPresentismo = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    // Manejar la selección del archivo
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    // Manejar el envío del archivo
    const handleUploadFile = async (e) => {
        e.preventDefault();
        if (!selectedFile) {
            setError("Por favor selecciona un archivo.");
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        // Obtener el token de autenticación
        const token = localStorage.getItem('jwt');
        const authHeader = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        };

        try {
            // Enviar el archivo al backend
            await axios.post(`${process.env.REACT_APP_API_URL}/api/presentismo/cargar`, formData, authHeader);
            setMessage("Archivo cargado y procesado correctamente.");
            setError(null);
        } catch (err) {
            setError("Error al cargar el archivo.");
            setMessage(null);
            console.error('Error al cargar el archivo:', err);
        }
    };

    return (
        <div className="container mt-4">
            <h1>Cargar Presentismo</h1>
            <Form onSubmit={handleUploadFile}>
                <Form.Group>
                    <Form.Label>Selecciona un archivo Excel</Form.Label>
                    <Form.Control type="file" onChange={handleFileChange} />
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-3">
                    Cargar
                </Button>
            </Form>
            {message && <Alert variant="success" className="mt-3">{message}</Alert>}
            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
        </div>
    );
};

export default CargaPresentismo;
