import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Card, Button, Row, Col, Form, Collapse } from 'react-bootstrap';
import banderas from '../utils/banderas';

const ActiveRoster = () => {
  const [jugadores, setJugadores] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGender, setSelectedGender] = useState(''); // Filtro de género
  const [selectedYears, setSelectedYears] = useState([]); // Filtro de año de nacimiento
  const [showYearFilter, setShowYearFilter] = useState(false); // Controlar la visibilidad de los años
  const navigate = useNavigate();

  // Cargar jugadores cuando se monta el componente
  useEffect(() => {
    fetchActiveJugadores();
  }, []);

  const fetchActiveJugadores = async () => {
    const token = localStorage.getItem('jwt');
    const authHeader = {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jugadores/active`, authHeader);
      setJugadores(response.data);
    } catch (error) {
      console.error('Error fetching jugadores:', error);
    }
  };

  const handleViewProfile = (jugadorId) => {
    navigate(`/jugador/${jugadorId}`);
  };

  // Manejar la selección de múltiples años de nacimiento
  const handleYearSelection = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedYears([...selectedYears, value]);
    } else {
      setSelectedYears(selectedYears.filter((year) => year !== value));
    }
  };

  // Obtener los años de nacimiento únicos de los jugadores, ordenados
  const availableYears = [...new Set(jugadores.map(jugador => new Date(jugador.nacimiento).getFullYear()))]
    .sort((a, b) => a - b);

  // Filtrar los jugadores según el término de búsqueda, género y año de nacimiento
  const filteredJugadores = jugadores.filter(jugador => {
    const cumpleBusqueda =
      (jugador.nombre ? jugador.nombre.toLowerCase() : '').includes(searchTerm.toLowerCase()) ||
      (jugador.apellido ? jugador.apellido.toLowerCase() : '').includes(searchTerm.toLowerCase()) ||
      (jugador.apodo ? jugador.apodo.toLowerCase() : '').includes(searchTerm.toLowerCase()) ||
      (jugador.casaca ? jugador.casaca.toString() : '').includes(searchTerm);

    const cumpleGenero = selectedGender ? jugador.genero === selectedGender : true;

    const anoNacimiento = jugador.nacimiento ? new Date(jugador.nacimiento).getFullYear().toString() : '';
    const cumpleAnoNacimiento = selectedYears.length > 0 ? selectedYears.includes(anoNacimiento) : true;

    return cumpleBusqueda && cumpleGenero && cumpleAnoNacimiento;
  });

  return (
    <div>
      <h1>Roster Activo</h1>
      <Row className="mb-3">
        <Col md={4}>
          <input
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="form-control"
          />
        </Col>

        <Col md={4}>
          <Form.Select value={selectedGender} onChange={(e) => setSelectedGender(e.target.value)}>
            <option value="">Todos</option>
            <option value="M">Masculino</option>
            <option value="F">Femenino</option>
          </Form.Select>
        </Col>

        <Col md={4}>
          {/* Botón para mostrar/ocultar el filtro por años */}
          <Button variant="secondary" onClick={() => setShowYearFilter(!showYearFilter)}>
            {showYearFilter ? 'Ocultar filtro por año' : 'Filtrar por año'}
          </Button>
          
          {/* El filtro de años está oculto hasta que se hace clic en el botón */}
          <Collapse in={showYearFilter}>
            <div className="mt-3">
              <div className="d-flex flex-wrap">
                {availableYears.map((year) => (
                  <Form.Check
                    key={year}
                    type="checkbox"
                    id={`year-${year}`}
                    label={year}
                    value={year}
                    checked={selectedYears.includes(year.toString())}
                    onChange={handleYearSelection}
                    className="mr-2"
                  />
                ))}
              </div>
            </div>
          </Collapse>
        </Col>
      </Row>

      <Row>
        {filteredJugadores.length > 0 ? (
          filteredJugadores.map((jugador) => {
            // Definir el fondo del Card según el género
            const cardStyle = {
              backgroundColor: jugador.genero === 'M' ? '#b3e5fc' : jugador.genero === 'F' ? '#f8bbd0' : ''
            };

            // Extraer el año de la fecha de nacimiento
            const anoNacimiento = jugador.nacimiento ? new Date(jugador.nacimiento).getFullYear() : 'No especificado';

            return (
              <Col key={jugador.id} md={4} className="mb-4">
                <Card style={cardStyle}>
                  <Card.Body>
                    <Card.Title>
                      {jugador.nombre}{" "}
                      {jugador.apodo && `"${jugador.apodo}"`} {/* Solo muestra el apodo y las comillas si existe */}
                      {jugador.apellido} #{jugador.casaca}
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      {jugador.nacionalidad && (
                        <img
                          src={banderas[jugador.nacionalidad].url}
                          alt={jugador.nacionalidad}
                          style={{ width: '30px', height: '20px', marginRight: '10px' }}
                        />
                      )}
                      Año de Nacimiento: {anoNacimiento}
                    </Card.Subtitle>
                    <Button variant="primary" onClick={() => handleViewProfile(jugador.id)}>
                      Ver Perfil
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            );
          })
        ) : (
          <p>No se encontraron jugadores</p>
        )}
      </Row>
    </div>
  );
};

export default ActiveRoster;
