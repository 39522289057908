import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { Card, Button, Form, Row, Col, ProgressBar, Modal, Table } from 'react-bootstrap';
import { formatFecha } from '../utils/dateUtils';
import tiposSesion from '../utils/tipoSesion';
import './SesionBullpen.css';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const SesionBullpen = () => {
  const [sesiones, setSesiones] = useState([]);
  const { jugadorId: jugadorIdURL } = useParams();
  const [jugadorId, setJugadorId] = useState(null);
  const [nuevaFecha, setNuevaFecha] = useState('');
  const [nuevoTipo, setNuevoTipo] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedSesion, setSelectedSesion] = useState(null);
  const [videoLink, setVideoLink] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    const authHeader = {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    const fetchJugadorLogueado = async () => {
      try {
        if (!jugadorIdURL) {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/profile`, authHeader);
          setJugadorId(response.data.Jugador.id);
        } else {
          setJugadorId(jugadorIdURL);
        }
      } catch (error) {
        console.error('Error al obtener los datos del jugador logueado', error);
      }
    };

    fetchJugadorLogueado();
  }, [jugadorIdURL]);

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    const authHeader = {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    const fetchSesiones = async () => {
      try {
        if (jugadorId) {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/sesionbullpen/jugador/${jugadorId}`, authHeader);
          console.log('Sesiones recibidas:', response.data);
          setSesiones(response.data);
        }
      } catch (error) {
        console.error('Error al obtener sesiones', error);
      }
    };

    fetchSesiones();
  }, [jugadorId]);

  const handleCrearSesion = async () => {
    const token = localStorage.getItem('jwt');
    const authHeader = {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    try {
      if (!nuevaFecha || !nuevoTipo) {
        alert('Por favor, ingresa tanto la fecha como el tipo de sesión.');
        return;
      }

      const nuevaSesion = await axios.post(`${process.env.REACT_APP_API_URL}/api/sesionbullpen/crear`, {
        IDJugador: jugadorId,
        Fecha: nuevaFecha,
        Tipo: nuevoTipo,
      }, authHeader);

      setSesiones([...sesiones, nuevaSesion.data]);
      setNuevaFecha('');
      setNuevoTipo('');
    } catch (error) {
      console.error('Error al crear sesión', error);
    }
  };

  const handleShowModal = (sesion) => {
    setSelectedSesion(sesion);
    setVideoLink(sesion.Link || '');
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedSesion(null);
    setVideoLink('');
  };

  const handleSaveLink = async () => {
    const token = localStorage.getItem('jwt');
    const authHeader = {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/sesionbullpen/${selectedSesion.IDSesionBullpen}/actualizar-link`, {
        link: videoLink,
      }, authHeader);

      // Actualiza el link en la sesión correspondiente
      setSesiones(sesiones.map(s => 
        s.IDSesionBullpen === selectedSesion.IDSesionBullpen ? { ...s, Link: videoLink } : s
      ));

      handleCloseModal();

    } catch (error) {
      console.error('Error al actualizar el link de video:', error);
    }
  };
  
  const calcularStrikePercentage = (lanzamientos) => {
    if (!lanzamientos || lanzamientos.length === 0) {
      return 0;
    }
    const strikes = lanzamientos.filter(lanzamiento => lanzamiento.Resultado >= 1 && lanzamiento.Resultado <= 5);
    return (strikes.length / lanzamientos.length) * 100;
  };

  const calcularPromedioVelocidadRecta = (lanzamientos = []) => {
    if (!Array.isArray(lanzamientos) || lanzamientos.length === 0) return 0; // Verifica que sea un array válido
    const rectas = lanzamientos.filter(lanzamiento => lanzamiento.TipoLanzamiento === 1 && lanzamiento.Velocidad > 0); // '1' para Recta
    if (rectas.length === 0) return 0;
    const totalVelocidad = rectas.reduce((total, lanzamiento) => total + lanzamiento.Velocidad, 0);
    return totalVelocidad / rectas.length;
  };

  // Ordena las sesiones de la más vieja a la más nueva para los gráficos
  const sesionesOrdenadasAsc = [...sesiones].sort((a, b) => new Date(a.Fecha) - new Date(b.Fecha));
  // Ordena las sesiones de la más nueva a la más vieja para la lista
  const sesionesOrdenadasDesc = [...sesiones].sort((a, b) => new Date(b.Fecha) - new Date(a.Fecha));

  // Datos para el gráfico de Promedio de Velocidad de Recta
  const promedioVelocidadData = {
    labels: sesionesOrdenadasAsc
      .filter(sesion => calcularPromedioVelocidadRecta(sesion.SesionBullpenLanzamientos || []) > 0) // Asegura que sea un array válido
      .map(sesion => formatFecha(sesion.Fecha)),
    datasets: [
      {
        label: 'Promedio Velocidad Recta (MPH)',
        data: sesionesOrdenadasAsc
          .filter(sesion => calcularPromedioVelocidadRecta(sesion.SesionBullpenLanzamientos) > 0)
          .map(sesion => calcularPromedioVelocidadRecta(sesion.SesionBullpenLanzamientos)),
        borderColor: 'rgba(75,192,192,1)',
        fill: false,
      },
    ],
  };

  // Datos para el gráfico de Velocidad Máxima
  const velocidadMaximaData = {
    labels: sesionesOrdenadasAsc
      .filter(sesion => sesion.velocidadMaxima > 0)
      .map(sesion => formatFecha(sesion.Fecha)),
    datasets: [
      {
        label: 'Velocidad Máxima (MPH)',
        data: sesionesOrdenadasAsc
          .filter(sesion => sesion.velocidadMaxima > 0)
          .map(sesion => sesion.velocidadMaxima),
        borderColor: 'rgba(255,99,132,1)',
        fill: false,
      },
    ],
  };

  // Datos para el gráfico de % de Strikes
  const porcentajeStrikesData = {
    labels: sesionesOrdenadasAsc.map(sesion => formatFecha(sesion.Fecha)),
    datasets: [
      {
        label: 'Porcentaje de Strikes (%)',
        data: sesionesOrdenadasAsc.map(sesion => calcularStrikePercentage(sesion.SesionBullpenLanzamientos || [])),
        borderColor: 'rgba(54,162,235,1)',
        fill: false,
      },
    ],
  };

  const jugadorNombre = sesiones.length > 0 ? sesiones[0]?.Jugador?.nombre : 'N/A';
  const jugadorApellido = sesiones.length > 0 ? sesiones[0]?.Jugador?.apellido : 'N/A';

  return (
    <div className="container mt-4">
      <h1>Sesiones de Bullpen de {jugadorNombre} {jugadorApellido}</h1>
    
      <Row>
        {/* Gráfico de Promedio Velocidad Recta */}
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Promedio Velocidad Recta</Card.Title>
              <Line data={promedioVelocidadData} />
            </Card.Body>
          </Card>
        </Col>

        {/* Gráfico de Velocidad Máxima */}
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Velocidad Máxima por Sesión</Card.Title>
              <Line data={velocidadMaximaData} />
            </Card.Body>
          </Card>
        </Col>

        {/* Gráfico de % de Strikes */}
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Porcentaje de Strikes por Sesión</Card.Title>
              <Line data={porcentajeStrikesData} />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <Card>  
            <Card.Header style={{ backgroundColor: '#e8f4fd' }}>Crear nueva sesion de Bullpen</Card.Header>
            <Card.Body>       
              <Form>
                <Form.Group controlId="formFecha">
                  <Form.Label><strong>Fecha de la Sesión</strong></Form.Label>
                  <Form.Control
                    type="date"
                    value={nuevaFecha}
                    onChange={(e) => setNuevaFecha(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formTipo" className="mt-3">
                  <Form.Label><strong>Tipo de Sesión</strong></Form.Label>
                  <Form.Control
                    as="select"
                    value={nuevoTipo}
                    onChange={(e) => setNuevoTipo(e.target.value)}
                  >
                    <option value="">Selecciona un tipo de sesión</option>
                    {tiposSesion.map((tipo) => (
                      <option key={tipo.value} value={tipo.value}>
                        {tipo.label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Button variant="primary" className="mt-3" onClick={handleCrearSesion}>
                  Crear nueva sesión
                </Button>
              </Form>
            </Card.Body> 
          </Card>
        </Col>
      </Row>

      {/* Lista de sesiones ordenadas de la más nueva a la más vieja */}
      <Row className="mb-4"> 
        <Col>
          <Card>
            <Card.Header style={{ backgroundColor: '#e8f4fd' }}>Sesiones Anteriores</Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Tipo</th>
                    <th>Strikes</th>
                    <th>MAX (MPH)</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {sesionesOrdenadasDesc.map((sesion) => {
                    const strikePercentage = calcularStrikePercentage(sesion.SesionBullpenLanzamientos);
                    return (
                      <tr key={sesion.IDSesionBullpen}>
                        <td>{formatFecha(sesion.Fecha)}</td>
                        <td>{tiposSesion.find(tipo => tipo.value === sesion.Tipo)?.label}</td>
                        <td><ProgressBar now={strikePercentage} label={`${strikePercentage.toFixed(2)}%`} /></td>
                        <td>{sesion.velocidadMaxima !== null && sesion.velocidadMaxima}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            <Link to={`/bullpen/sesion/${sesion.IDSesionBullpen}`}>
                              <Button variant="primary" className="me-2">Ver detalles</Button>
                            </Link>
                            {sesion.Link ? (
                              <Button
                                variant="success"
                                onClick={() => window.open(sesion.Link, '_blank')}
                              >
                                Ver Video
                              </Button>
                            ) : (
                              <Button variant="warning" onClick={() => handleShowModal(sesion)}>
                                Cargar Video
                              </Button>
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal para cargar el enlace de video */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Cargar Enlace de Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Enlace de Video</Form.Label>
            <Form.Control
              type="url"
              value={videoLink}
              onChange={(e) => setVideoLink(e.target.value)}
              placeholder="Ingresa el enlace del video"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSaveLink}>
            Guardar Enlace
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SesionBullpen;
