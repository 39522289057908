// src/utils/categorias.js
const categorias = {
    1: 'A1',
    2: 'A2',
    3: 'A3',
    4: 'U23',
    5: 'U18',
    6: 'U17',
    7: 'U15',
    8: 'U14',
    9: 'U13',
    10: 'U12',
    11: 'U10',
    12: 'Femenino',
    13: 'U8',
    14: 'Iniciacion',
    16: 'Manager',
    18: 'Top 6'


  };
  
  export default categorias;
  