// src/utils/tipoSesion.js
const tiposSesion = [
  { value: 1, label: 'Mound A' },
  { value: 2, label: 'Mound B' },
  { value: 3, label: 'Enfrentamiento' },
  { value: 4, label: 'Recovery Short Box' },
  { value: 5, label: 'Command' },
  { value: 6, label: 'Partido' }
];

export default tiposSesion;