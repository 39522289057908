// src/utils/dateUtils.js

export const formatFecha = (fecha) => {
    const date = new Date(fecha);
    
    if (isNaN(date.getTime())) {
      return 'Fecha inválida'; // Manejo de error si la fecha no es válida
    }
  
    return new Intl.DateTimeFormat('es-AR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(date);
  };