// src/utils/equipos.js
const equipos = {
    1: 'Velez',
    2: 'Aguilas',
    3: 'Comunicaciones',
    4: 'Daom',
    5: 'Jupiter',
    6: 'Ferro',
    7: 'Lanus',
    11: 'Nichia',
    12: 'La Plata'


  };
  
  export default equipos;
  