import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form, Modal, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import categorias from '../utils/categorias';
import banderas from '../utils/banderas';
import dayjs from 'dayjs';
import { FaEdit, FaTrashAlt, FaDownload } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const EquiposList = () => {
  const [equipos, setEquipos] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    manager: '',
    torneo: '',
    categoria: '',
    estado: 'Y',
  });
  const [showModal, setShowModal] = useState(false);
  const [showModalJugadores, setShowModalJugadores] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedEquipoId, setSelectedEquipoId] = useState(null);
  const [newEquipo, setNewEquipo] = useState({
    nombre: '',
    categoria_id: '',
    temporada: '',
    manager_id: '',
    torneo_id: '',
    estado: 'Y',
  });
  const [jugadores, setJugadores] = useState([]);
  const [torneos, setTorneos] = useState([]);

  const [jugadoresEquipo, setJugadoresEquipo] = useState([]);
  const [selectedEquipo, setSelectedEquipo] = useState(null);
  const [availableJugadores, setAvailableJugadores] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchEquipos = async () => {
      const token = localStorage.getItem('jwt');
      if (!token) {
        navigate('/login'); // Si no hay token, redirigir al login
        return;
      }

      const authHeader = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/equiposvelez`, authHeader);
        setEquipos(response.data);
        const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/profile`, authHeader);
        setUserRole(userResponse.data.role);

        // Obtener los jugadores activos y ordenarlos alfabéticamente
        const jugadoresResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/jugadores/active`, authHeader);
        const jugadoresOrdenados = jugadoresResponse.data.sort((a, b) => a.nombre.localeCompare(b.nombre));
        setJugadores(jugadoresOrdenados);
        setAvailableJugadores(jugadoresOrdenados);

        // Obtener los torneos
        const torneosResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/torneos`, authHeader);
        setTorneos(torneosResponse.data);
      } catch (error) {
        console.error('Error al obtener los equipos:', error);
      }
    };
    fetchEquipos();
  }, [navigate]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const filteredEquipos = equipos.filter((equipo) => {
    return (
      (equipo.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
        equipo.temporada.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (!filters.manager || equipo.manager_id === parseInt(filters.manager)) &&
      (!filters.torneo || equipo.torneo_id === parseInt(filters.torneo)) &&
      (!filters.categoria || equipo.categoria_id === parseInt(filters.categoria)) &&
      (!filters.estado || equipo.estado === filters.estado)
    );
  });

  const handleShowModal = (equipo = null) => {
    if (equipo) {
      setEditMode(true);
      setSelectedEquipoId(equipo.id);
      setNewEquipo({
        nombre: equipo.nombre,
        categoria_id: equipo.categoria_id,
        temporada: equipo.temporada,
        manager_id: equipo.manager_id,
        torneo_id: equipo.torneo_id,
        estado: equipo.estado,
      });
    } else {
      setEditMode(false);
      setNewEquipo({
        nombre: '',
        categoria_id: '',
        temporada: '',
        manager_id: '',
        torneo_id: '',
        estado: 'Y',
      });
    }
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleCloseModalJugadores = () => setShowModalJugadores(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEquipo({ ...newEquipo, [name]: value });
  };

  const handleCreateOrUpdateEquipo = async () => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      navigate('/login');
      return;
    }

    const authHeader = {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    try {
      if (editMode) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/equiposvelez/${selectedEquipoId}`, newEquipo, authHeader);
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/equiposvelez/create`, newEquipo, authHeader);
      }
      setShowModal(false);
      window.location.reload(); // Recargar la página para mostrar los cambios
    } catch (error) {
      console.error('Error al crear o actualizar el equipo:', error);
    }
  };

  const handleDeleteEquipo = async (equipoId) => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      navigate('/login');
      return;
    }

    const authHeader = {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/equiposvelez/${equipoId}/inactivate`, {}, authHeader);
      window.location.reload();
    } catch (error) {
      console.error('Error al inactivar el equipo:', error);
    }
  };

  const fetchJugadoresEquipo = async (equipoId) => {
    const token = localStorage.getItem('jwt');
    const authHeader = { headers: { 'Authorization': `Bearer ${token}` } };
  
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/equiposvelez/${equipoId}/jugadores`, authHeader);
      console.log('jugadores equipo',response.data);
      setJugadoresEquipo(response.data);
      setSelectedEquipo(equipoId);
      setShowModalJugadores(true);
    } catch (error) {
      console.error('Error al obtener los jugadores:', error);
    }
  };
  
  const handleAddJugador = async (jugadorId) => {
    const token = localStorage.getItem('jwt');
    const authHeader = { headers: { 'Authorization': `Bearer ${token}` } };
  
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/equiposvelez/agregar-jugador`, { equipo_id: selectedEquipo, jugador_id: jugadorId }, authHeader);
      fetchJugadoresEquipo(selectedEquipo);
    } catch (error) {
      console.error('Error al agregar jugador:', error);
    }
  };
  
  const handleRemoveJugador = async (jugadorId) => {
    const token = localStorage.getItem('jwt');
    const authHeader = { headers: { 'Authorization': `Bearer ${token}` } };
  
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/equiposvelez/quitar-jugador`, { equipo_id: selectedEquipo, jugador_id: jugadorId }, authHeader);
      fetchJugadoresEquipo(selectedEquipo);
    } catch (error) {
      console.error('Error al eliminar jugador:', error);
    }
  };
  
  const handleDownloadExcel = () => {
    const data = jugadoresEquipo.map((jugador) => ({
      Nombre: jugador.nombre,
      Apellido: jugador.apellido,
      Nacimiento: dayjs(jugador.nacimiento).format('DD/MM/YYYY'),
      Casaca: jugador.casaca,
      Nacionalidad: banderas[jugador.nacionalidad]?.nombre || 'Desconocido',
      DNI: jugador.dni,
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Jugadores');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    saveAs(blob, `jugadores_equipo_${selectedEquipo}.xlsx`);
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <h1 className="mb-4">Equipos Vélez</h1>
          <Form className="mb-4">
            <Row>
              <Col md={4}>
                <Form.Control
                  type="text"
                  placeholder="Buscar por nombre o temporada"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </Col>
              <Col md={2}>
                <Form.Select name="manager" value={filters.manager} onChange={handleFilterChange}>
                  <option value="">Filtrar por Manager</option>
                  {jugadores.map((jugador) => (
                    <option key={jugador.id} value={jugador.id}>
                      {jugador.nombre} {jugador.apellido}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col md={2}>
                <Form.Select name="torneo" value={filters.torneo} onChange={handleFilterChange}>
                  <option value="">Filtrar por Torneo</option>
                  {torneos.map((torneo) => (
                    <option key={torneo.id} value={torneo.id}>{torneo.Nombre}</option>
                  ))}
                </Form.Select>
              </Col>
              <Col md={2}>
                <Form.Select name="categoria" value={filters.categoria} onChange={handleFilterChange}>
                  <option value="">Filtrar por Categoría</option>
                  {Object.entries(categorias).map(([id, nombre]) => (
                    <option key={id} value={id}>{nombre}</option>
                  ))}
                </Form.Select>
              </Col>
              <Col md={2}>
                <Form.Select name="estado" value={filters.estado} onChange={handleFilterChange}>
                  <option value="Y">Activos</option>
                  <option value="N">Inactivos</option>
                </Form.Select>
              </Col>
            </Row>
          </Form>
          {['admin', 'coach'].includes(userRole) && (
            <Button variant="primary" className="mb-4" onClick={() => handleShowModal()}>
              Crear Nuevo Equipo
            </Button>
          )}
          {equipos.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Categoría</th>
                  <th>Temporada</th>
                  <th>Manager</th>
                  <th>Torneo</th>
                  <th>Estado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {filteredEquipos.map((equipo) => (
                  <tr key={equipo.id}>
                    <td>{equipo.nombre}</td>
                    <td>{categorias[equipo.categoria_id]}</td>
                    <td>{equipo.temporada}</td>
                    <td>{equipo.Manager ? `${equipo.Manager.nombre} ${equipo.Manager.apellido}` : 'N/A'}</td>
                    <td>{equipo.Torneo ? equipo.Torneo.nombre : 'N/A'}</td>
                    <td>{equipo.estado === 'Y' ? 'Activo' : 'Inactivo'}</td>
                    <td>
                      <Button variant="warning" className="me-2" onClick={() => handleShowModal(equipo)}>
                        <FaEdit />
                      </Button>
                      <Button variant="danger" onClick={() => handleDeleteEquipo(equipo.id)}>
                        <FaTrashAlt />
                      </Button>
                      
                      <Button variant="info" onClick={() => fetchJugadoresEquipo(equipo.id)}>Ver Jugadores</Button>{' '}
                      <Button variant="success" onClick={handleDownloadExcel} disabled={!jugadoresEquipo.length}>
                        <FaDownload /> Descargar Excel
                      </Button>

                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No hay equipos disponibles.</p>
          )}
        </Col>
      </Row>

      {/* Modal para crear o editar un equipo */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editMode ? 'Editar Equipo' : 'Crear Nuevo Equipo'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Nombre del Equipo</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                value={newEquipo.nombre}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Categoría</Form.Label>
              <Form.Select name="categoria_id" value={newEquipo.categoria_id} onChange={handleInputChange}>
                <option value="">Seleccione una categoría</option>
                {Object.entries(categorias).map(([id, nombre]) => (
                  <option key={id} value={id}>{nombre}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Temporada</Form.Label>
              <Form.Control
                type="text"
                name="temporada"
                value={newEquipo.temporada}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Manager</Form.Label>
              <Form.Select name="manager_id" value={newEquipo.manager_id} onChange={handleInputChange}>
                <option value="">Seleccionar Manager</option>
                {jugadores.map((jugador) => (
                  <option key={jugador.id} value={jugador.id}>
                    {jugador.nombre} {jugador.apellido}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Torneo</Form.Label>
              <Form.Select name="torneo_id" value={newEquipo.torneo_id} onChange={handleInputChange}>
                <option value="">Seleccionar Torneo</option>
                {torneos.map((torneo) => (
                  <option key={torneo.id} value={torneo.id}>{torneo.Nombre}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Estado</Form.Label>
              <Form.Select name="estado" value={newEquipo.estado} onChange={handleInputChange}>
                <option value="Y">Activo</option>
                <option value="N">Inactivo</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Cancelar</Button>
          <Button variant="primary" onClick={handleCreateOrUpdateEquipo}>{editMode ? 'Guardar Cambios' : 'Crear Equipo'}</Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para jugadores del equipo */}
      <Modal show={showModalJugadores} onHide={handleCloseModalJugadores}>
        <Modal.Header closeButton>
          <Modal.Title>Jugadores del Equipo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {jugadoresEquipo.map((jugador) => (
                <tr key={jugador.id}>
                  <td>{jugador.nombre} {jugador.apellido}</td>
                  <td>
                    <Button variant="danger" onClick={() => handleRemoveJugador(jugador.id)}>Quitar</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Form.Select onChange={(e) => handleAddJugador(e.target.value)}>
            <option value="">Agregar Jugador</option>
            {availableJugadores.map((jugador) => (
              <option key={jugador.id} value={jugador.id}>
                {jugador.nombre} {jugador.apellido}
              </option>
            ))}
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalJugadores}>Cerrar</Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
};

export default EquiposList;
