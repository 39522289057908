import React, { useEffect, useState } from 'react';
import { Container, Navbar, Nav, Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import banderas from '../utils/banderas';

const Layout = ({ children }) => {
  const [user, setUser] = useState(null);
  const [jugador, setJugador] = useState(null); // Estado específico para el jugador
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axios.get('/api/auth/logout'); // Llamada a la API de logout
      localStorage.removeItem('jwt'); // Remover el token almacenado
      setUser(null); // Limpia el estado del usuario
      navigate('/login'); // Redirigir a la página de inicio de sesión
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('jwt');
      if (!token) {
        navigate('/login'); // Si no hay token, redirigir al login
        return;
      }

      const authHeader = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/profile`, authHeader);
        console.log('Datos del usuario recibidos:', response.data);
        setUser(response.data);

        // Verificar si el jugador existe dentro del usuario recibido
        if (response.data && response.data.Jugador) {
          setJugador(response.data.Jugador); // Aquí guardamos los datos del jugador si existen
          console.log('Jugador encontrado:', response.data.Jugador);
        } else {
          console.log('El usuario no tiene un jugador asociado.');
        }
      } catch (error) {
        console.error('Error al obtener el perfil del usuario:', error);
        // Si el token es inválido o expira, redirigir al login
        navigate('/login');
      }
    };
    fetchUser();
  }, [navigate]);

  if (!user) {
    return <p>Cargando información del usuario...</p>;
  }

  return (
    <div className="d-flex">
      {/* Panel Lateral */}
      <div className="sidebar bg-primary text-white p-3" style={{ width: '250px', minHeight: '100vh' }}>
        <div className="user-profile text-center mb-4">
          <Image
            src={jugador.foto || '/default-profile.jpg'} // Usamos la foto de perfil del usuario o una por defecto
            roundedCircle
            fluid
            className="mb-2"
            style={{ width: '100px', height: '100px' }}
          />
          <h5>{jugador ? `${jugador.nombre} ${jugador.apellido}` : 'Nombre del Jugador'}</h5>
          <p>#{jugador ? jugador.casaca : 'N/A'}</p>
          {jugador && jugador.nacionalidad && (
            <img
              src={banderas[jugador.nacionalidad].url} // Usamos la bandera correspondiente
              alt="Bandera"
              style={{ width: '30px', height: '20px' }}
            />
          )}
          <p>{user.role || 'Sin Rol'}</p>
        </div>

        {jugador ? (
          <Nav className="flex-column">
          {/* Opciones visibles para todos los usuarios */}
          <Nav.Link as={Link} to="/profile" className="text-white">
            Mi Perfil
          </Nav.Link>                       
          <Nav.Link as={Link} to="/bullpen" className="text-white">
            Bullpen
          </Nav.Link>
          <Nav.Link as={Link} to={"/blast"} className="text-white">
            Mediciones Blast
          </Nav.Link>
          <Nav.Link as={Link} to="/equiposvelez" className="text-white">
            Equipos Vélez
          </Nav.Link>
        
          {/* Opciones para Coach y Admin */}
          {['coach', 'admin'].includes(user.role) && (
            <>
              <Nav.Link as={Link} to="/admin/ControlAranceles" className="text-white">
                Control Aranceles
              </Nav.Link>
              <Nav.Link as={Link} to="/admin/ControlPresentismo" className="text-white">
                Control Presentismo
              </Nav.Link>
              <Nav.Link as={Link} to="/admin/Torneos" className="text-white">
                Torneos
              </Nav.Link>
              <Nav.Link as={Link} to="/admin/Partidos" className="text-white">
                Partidos
              </Nav.Link>
              <Nav.Link as={Link} to="/entrenamiento/admin" className="text-white">
                Administración Entrenamiento
              </Nav.Link>
              <Nav.Link as={Link} to="/entrenamiento/semanal" className="text-white">
                Entrenamiento Semanal
              </Nav.Link>
            </>
          )}
        
          {/* Opciones solo para Admin */}
          {user.role === 'admin' && (
            <>
              <Nav.Link as={Link} to="/admin/players" className="text-white">
                Administrar Jugadores
              </Nav.Link>
              <Nav.Link as={Link} to="/admin/aranceles" className="text-white">
                Cargar Aranceles
              </Nav.Link>
              <Nav.Link as={Link} to="/admin/presentes" className="text-white">
                Cargar Presentismo
              </Nav.Link>
            </>
          )}
        
          {/* Opción de Cerrar Sesión para todos */}
          <Nav.Link onClick={handleLogout} className="text-white">
            Cerrar Sesión
          </Nav.Link>
        </Nav>
        
        ) : (
          <p className="text-danger text-center">
            Su información de jugador aún no está vinculada. Contacte al administrador.
          </p>
        )}
      </div>

      {/* Contenido Principal */}
      <div className="content flex-grow-1">
        {/* Barra Superior */}
        <Navbar bg="light" expand="lg" className="mb-4">
          <Container fluid>
            <Navbar.Brand as={Link} to="/">Inicio</Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/active-roster">Roster Activo</Nav.Link>
              {/* Menú adicional solo para admin */}
              {user.role === 'admin' && (
                <Nav.Link as={Link} to="/admin/players">
                  Administrar Jugadores
                </Nav.Link>
              )}
            </Nav>
          </Container>
        </Navbar>

        {/* Aquí se mostrará el contenido de cada página */}
        <Container fluid>
          {children}
        </Container>
      </div>
    </div>
  );
};

export default Layout;
